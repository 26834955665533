<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <CardNaskahTable
          :data="items.data"
          :columns="table1Columns"
          @filter="tipe"
        ></CardNaskahTable>
      </a-col>
    </a-row>

    <div class="mx-5 text-center">
      <a-pagination
        v-model="page"
        :total="items.last_page * 10"
        show-less-items
        @change="getData()"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CardNaskahTable from "../components/Cards/CardNaskahTable";
const table1Columns = [
  {
    title: "COVER",
    dataIndex: "cover_url",
    scopedSlots: { customRender: "cover_url" },
  },
  {
    title: "TITLE",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "AUTHOR",
    dataIndex: "author",
    scopedSlots: { customRender: "author" },
  },
  {
    title: "PUBLISHER",
    dataIndex: "user",
    scopedSlots: { customRender: "user" },
  },
  {
    title: "MARKET",
    dataIndex: "market",
    scopedSlots: { customRender: "market" },
  },
];
const table1Data = [
  {
    key: "1",
    author: {
      avatar: "images/face-2.jpg",
      name: "Michael John",
      email: "michael@mail.com",
    },
    func: {
      job: "Manager",
      department: "Organization",
    },
    status: 1,
    employed: "23/04/18",
  },
  {
    key: "2",
    author: {
      avatar: "images/face-3.jpg",
      name: "Alexa Liras",
      email: "alexa@mail.com",
    },
    func: {
      job: "Programator",
      department: "Developer",
    },
    status: 0,
    employed: "23/12/20",
  },
  {
    key: "3",
    author: {
      avatar: "images/face-1.jpg",
      name: "Laure Perrier",
      email: "laure@mail.com",
    },
    func: {
      job: "Executive",
      department: "Projects",
    },
    status: 1,
    employed: "13/04/19",
  },
  {
    key: "4",
    author: {
      avatar: "images/face-4.jpg",
      name: "Miriam Eric",
      email: "miriam@mail.com",
    },
    func: {
      job: "Marketing",
      department: "Organization",
    },
    status: 1,
    employed: "03/04/21",
  },
  {
    key: "5",
    author: {
      avatar: "images/face-5.jpeg",
      name: "Richard Gran",
      email: "richard@mail.com",
    },
    func: {
      job: "Manager",
      department: "Organization",
    },
    status: 0,
    employed: "23/03/20",
  },
  {
    key: "6",
    author: {
      avatar: "images/face-6.jpeg",
      name: "John Levi",
      email: "john@mail.com",
    },
    func: {
      job: "Tester",
      department: "Developer",
    },
    status: 0,
    employed: "14/04/17",
  },
];

export default {
  components: {
    CardNaskahTable,
  },
  data() {
    return {
      table1Data: table1Data,
      table1Columns: table1Columns,

      page: 1,
      type: "all",
      items: [],
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    tipe: function (str) {
      this.type = str;
	  this.getData()
    },
    getData() {
      axios({
        method: "get",
        url: "admin/naskah?page=" + this.page + "&type=" + this.type,
      }).then((response) => {
        this.items = response.data.data.data;
        // console.log(this.items.last_page)
      });
    },
  },
};
</script>

<style lang="scss"></style>
