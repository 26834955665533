<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Data Naskah</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="dataType" size="small" @change="filterMarket()">
						<a-radio-button value="all">ALL</a-radio-button>
						<a-radio-button value="market">MARKET</a-radio-button>
					</a-radio-group>
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="false">

			<template slot="row" slot-scope="title">
				<div class="table-avatar-info">
					<div class="avatar-info">
						<h6>{{ title }}</h6>
					</div>
				</div>
			</template>

			<template slot="cover_url" slot-scope="cover_url">
				<img :src="cover_url" height="50px">
			</template>

			<template slot="author" slot-scope="author">
				<div class="table-avatar-info">
					<div class="avatar-info">
						<h6>{{ author }}</h6>
					</div>
				</div>
			</template>

			<template slot="user" slot-scope="user">
				<div class="table-avatar-info">
					<div class="avatar-info">
						<h6>{{ user.name }}</h6>
					</div>
				</div>
			</template>

			<template slot="market" slot-scope="market">
				<a-tag class="tag-status" :class="market ? 'ant-tag-primary' : 'ant-tag-muted'">
					{{ market ? "MARKET" : "NO" }}
				</a-tag>
			</template>

			<!-- <template slot="editBtn" slot-scope="row">
				<a-button type="link" :data-id="row.key" class="btn-edit">
					Edit
				</a-button>
			</template> -->

		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				dataType: 'all',
			}
		},
		methods:{
			filterMarket(){
				this.$emit("filter", this.dataType);
				console.log(this.dataType)
			}
		}
	})

</script>